import JsSIP from "jssip";
import {
  usePhoneStore,
  useInboxStore,
  useAppStore,
  useAxios,
  sendNotification,
  inbox,
  phoneSounds,
  getMicrophonePermission,
} from "@/utils";
import { i18n } from "@/plugins/i18n";

export default class Phone {
  async innit(telServer, callerid, md5secret, remoteView, selfView) {
    // this.sound = sound;

    this.remoteView = remoteView;
    this.selfView = selfView;

    this.ua = null;
    this.line1 = null;
    this.telServer = telServer;
    this.callerId = callerid;
    this.startDTMF = null;
    this.conferenceNumber = null;

    if (navigator.userAgent.indexOf("Chrome") != -1) {
      this.voice =
        "{ mandatory : { googEchoCancellation: true, googEchoCancellation2: true, googAutoGainControl: true, googNoiseSuppression: true, googHighpassFilter: true, googTypingNoiseDetection: true } }";
    } else this.voice = true;

    this.socket = new JsSIP.WebSocketInterface(
      "wss://" + telServer + ":8089/ws"
    );

    this.configuration = {
      sockets: [this.socket],
      uri: "sip:" + callerid + "@" + telServer,
      ha1: md5secret,
      session_timers: false,
      realm: "ucontact",
      no_answer_timeout: 1000,
      register_expires: 60,
    };

    await this.getStunTurnConfigs();

    console.log("Initialized " + this.callerId + " phone");

    //cortar la llamada antes de cerrar la ventana de chrome
    window.onbeforeunload = () => {
      if (this.line1 != null) this.hangUp();

      return null;
    };
  }

  async getStunTurnConfigs() {
    if (this.stunTurnConfigs) return;
    let res = await useAxios({
      method: "GET",
      url: `/api/me/phone/configs`,
      params: {},
      headers: {},
    });
    if (res.success) {
      const configs = JSON.parse(res.res.request.response).result;
      this.stunTurnConfigs = configs;
    } else {
      this.stunTurnConfigs = {};
    }
  }
  getInStream() {
    return this.remoteView.srcObject;
  }
  connectPhone(changedPhone) {
    if (this.ua === null) {
      this.changedPhone = changedPhone;
      this.ua = new JsSIP.UA(this.configuration);
      this.ua.start();
      this.attachUAEventHandlers();
    }
  }

  attachUAEventHandlers() {
    this.ua.on("registered", () => {
      usePhoneStore().setPhoneState("Registrado", this.callerId == "test");
      this.changedPhone = false;
    });
    this.ua.on("registrationFailed", e => {
      console.log(e);
      usePhoneStore().setPhoneState(
        "Error en el registro",
        this.callerId == "test"
      );
      if (this.changedPhone) {
        setTimeout(() => {
          this.connectPhone(true);
        }, 500);
      }
    });
    this.ua.on("connecting", () => {
      usePhoneStore().setPhoneState("Conectando", this.callerId == "test");
    });
    this.ua.on("connected", () => {});

    this.ua.on("unregistered", () => {
      usePhoneStore().setPhoneState("Desregistrado", this.callerId == "test");
    });

    this.ua.on("newRTCSession", data => {
      if (this.line1 != null) {
        if (!data.session.isEnded()) data.session.terminate();

        return;
      }
      this.newRTCSessionStartTime = new Date();
      let userNumber = data.session.remote_identity.uri.user;

      let username = "";
      let name = "";
      let hasAvatar = false;
      if (data.request.from.display_name) {
        name = data.request.from.display_name.split("|")[0];
        username = data.request.from.display_name.split("|")[1];
        hasAvatar = data.request.from.display_name.split("|")[2] == "true";
      }

      let campaign = "";
      let guid = "";

      this.line1 = data.session;
      if (this.line1 != null && data.originator == "remote") {
        usePhoneStore().setCallState("ringing", this.callerId == "test");
        sendNotification(
          "/attachments/avatars/logo.svg",
          "Llamada entrante",
          i18n.t("Inbound call"),
          i18n.t("Number:", { number: userNumber }),
          () => this.answer()
        );

        const interaction = useInboxStore().ringingInteractions.find(
          i => i.clientId == userNumber
        );
        if (interaction) {
          campaign = interaction.campaign;
          guid = interaction.guid;
          const storeCampaign = useInboxStore().campaigns.find(
            c => c.name == campaign
          );
          if (
            interaction.data?.DIALERDIRECTION == "in" ||
            (storeCampaign && storeCampaign.autoAnswer)
          ) {
            this.answer();
            inbox().playSound(useAppStore().preference.sounds.message);
          }
        }
      }

      usePhoneStore().setClient(
        {
          userNumber: userNumber,
          username: username,
          campaign: campaign,
          guid: guid,
          name: name,
          originHasAvatar: hasAvatar,
        },
        this.callerId == "test"
      );

      this.attachCallEventHandlers();
    });
  }

  async answer() {
    let iceServers = {};
    let urls = ["stun:stun.l.google.com:19302", "stun:stun.ucontactcloud.com"];
    if (this.stunTurnConfigs.PhoneEnabled) {
      urls.push("turn:" + this.stunTurnConfigs.ScreenShareTurn);
      iceServers.username = this.stunTurnConfigs.ScreenShareTurnUser;
      iceServers.credential = this.stunTurnConfigs.ScreenShareTurnPass;
    }
    iceServers.urls = urls;

    if (
      this.line1 != null &&
      !this.line1.isEstablished() &&
      this.line1.direction != "outgoing"
    ) {
      var options = {
        mediaConstraints: {
          audio: this.voice,
          video: false,
        },
        pcConfig: {
          iceServers: [iceServers],
        },
      };
      await getMicrophonePermission();
      try {
        this.line1.answer(options);
        usePhoneStore().setCallState("onCall", this.callerId == "test");
      } catch (ex) {
        this.hangUp();
      }
    }
  }

  attachCallEventHandlers() {
    var myCandidateTimeout = null;

    this.line1.on("icecandidate", function (candidate) {
      if (myCandidateTimeout != null) clearTimeout(myCandidateTimeout);

      // 3 seconds timeout after the last icecandidate received!
      myCandidateTimeout = setTimeout(candidate.ready, 3000);
    });
    this.line1.on("connecting", () => {
      usePhoneStore().setCallState("connecting", this.callerId == "test");
      this.attachRTCHandlers();
    });
    this.line1.on("accepted", () => {
      usePhoneStore().setCallState("onCall", this.callerId == "test");
      if (this.startDTMF != null) {
        this.sendDTMF(this.startDTMF);
        this.startDTMF = null;
      }

      phoneSounds().StopRing();
    });
    this.line1.on("ended", () => {
      usePhoneStore().setCallState("available", this.callerId == "test");
      usePhoneStore().setClient(
        {
          userNumber: "",
          username: "",
          campaign: "",
          guid: "",
        },
        this.callerId == "test"
      );
      useInboxStore().resetPhoneStates();
      useInboxStore().removetempContacts();

      if (this.selfView.srcObject)
        this.selfView.srcObject.getTracks().forEach(track => track.stop());
      if (this.remoteView.srcObject)
        this.remoteView.srcObject.getTracks().forEach(track => track.stop());

      this.line1 = null;
    });

    this.line1.on("failed", () => {
      usePhoneStore().setCallState("available", this.callerId == "test");
      usePhoneStore().setClient(
        {
          userNumber: "",
          username: "",
          campaign: "",
          guid: "",
        },
        this.callerId == "test"
      );

      phoneSounds().StopRing();
      this.line1 = null;
    });
  }

  attachRTCHandlers() {
    if (this.line1 != null) {
      if (this.line1 != null) {
        //outbound calls
        this.line1.connection.onaddstream = data => {
          this.remoteView.srcObject = data.stream;
        };

        this.line1.connection.ontrack = data => {
          this.remoteView.srcObject = data.streams[0];
        };

        //inbound calls
        this.line1.connection.addEventListener(
          "iceconnectionstatechange",
          data => {
            if (data.target.iceConnectionState == "connected") {
              let localStream = new MediaStream();
              this.line1._connection.getReceivers().forEach(receiver => {
                localStream.addTrack(receiver.track);
              });
              this.remoteView.srcObject = localStream;
            }
          }
        );
      }
    }
  }

  async makeCall(
    destination,
    campaign,
    contactId = "",
    noStore,
    context,
    startDTMF
  ) {
    if (this.ua?.status === 1 && this.line1 == null) {
      if (!noStore) {
        usePhoneStore().setCallState("calling", this.callerId == "test");
      }

      const headers =
        this.callerId == "test"
          ? ["CONTEXT:" + context]
          : [
              "CAMPAIGN: " + campaign,
              "USERNAME: " + useAppStore().user.username,
              "NAME: " + useAppStore().user.name,
              "AVATAR: " + String(!!useAppStore().user.avatar),
            ];

      if (campaign) {
        headers.push("CONTACTID: " + contactId);
      }

      let options = {
        mediaConstraints: {
          audio: this.voice,
          video: false,
        },
        extraHeaders: headers,
        pcConfig: {
          iceServers: [
            {
              urls: [
                "stun:stun.l.google.com:19302",
                "stun:stun.ucontactcloud.com",
              ],
            },
          ],
        },
      };

      this.startDTMF = startDTMF;
      this.ua.call("sip:" + destination + "@" + this.telServer, options);
      phoneSounds().StartRing(true);
      await getMicrophonePermission();
    } else {
      // Notify already in call
    }
  }

  hangUp() {
    if (this.ua != null) {
      this.ua.terminateSessions();
    }
    this.line1 = null;

    phoneSounds().PlayHangup();
  }

  sendDTMF(value) {
    if (this.line1 != null) {
      this.line1.sendDTMF(value);
    }
  }

  mute() {
    if (this.line1 != null) {
      this.line1.mute();
      useInboxStore().mute();
    }
  }

  unMute() {
    if (this.line1 != null) {
      this.line1.unmute();
      useInboxStore().unMute();
    }
  }

  hold() {
    if (this.line1 != null) {
      this.line1.hold();
      useInboxStore().hold();
    }
  }

  unHold() {
    if (this.line1 != null) {
      this.line1.unhold();
      useInboxStore().unHold();
    }
  }
  unregister() {
    if (this.ua) this.ua.terminateSessions();

    this.line1 = null;
    this.ua?.stop();
  }
}
