import { defineStore } from "pinia";
import User from "@/controllers/user";

export const useAppStore = defineStore("app", {
  state: () => {
    return {
      user: null,
      profile: null,
      preferences: {},
      preference: null,
      preDispositions: {},
      serverDate: null,
      serverDiff: 0,
    };
  },

  actions: {
    async updateUser(data) {
      this.user = {
        username: data.username,
        name: data.name,
        phone: data.phone,
        md5Secret: data.md5Secret,
        host: data.host,
        avatar: data.avatar,
        licenciator: data.licenciator,
        superuser: data.superuser,
        expirationDate: data?.expirationDate,
        validityDate: data?.validityDate,
        scheduled: data?.scheduled,
        crmDomain: data.crmDomain,
      };

      if (!this.preferences[this.user.username]) {
        const basePreferences = {
          language: "en",
          theme: { dark: false, primary: "#0095FF" },
          menuLastPage: {},
          sounds: {
            message: "notification_decorative-02",
            interaction: "notification_decorative-02",
            ringtone: "office-telephone",
            volume: 100,
          },
          submenuOpen: true,
          lastCreatedInteraction: null,
          fullscreenForms: [],
          rankingTemplates: [],
          rankingStatus: [],
          emojisRecent: [],
          emojisSkinTone: "default",
          tabs: {
            campaign: [],
            dialer: [],
            wallboard: [],
          },
          dashboards: {
            campaigns: null,
            home: null,
          },
          startAsUnavailable: false,
          interactionDrawer: true,
        };
        this.preferences[this.user.username] = basePreferences;
      }

      this.preference = this.preferences[this.user.username];

      if (data.preference) {
        let dataAux = JSON.parse(data.preference);
        if (!dataAux.tabs)
          dataAux.tabs = {
            campaign: [],
            dialer: [],
            wallboard: [],
          };

        this.preference = dataAux;
        this.preferences[this.user.username] = dataAux;
      }

      const newAtrributes = {
        rankingStatus: [],
        startAsUnavailable: false,
        emojisRecent: [],
        emojisSkinTone: "default",
        interactionDrawer: true,
      };
      for (const key in newAtrributes) {
        if (this.preference[key] == undefined)
          this.preference[key] = newAtrributes[key];
      }
    },
    async updatePreferences() {
      this.preference.tabs = {
        campaign: [],
        dialer: [],
        wallboard: [],
      };

      await User.updatePreferences(this.preference);
    },

    resetPassword(pass) {
      this.user.md5Secret = pass;
    },
    updateProfile(data) {
      if (!data) return;
      this.user.superuser = data.superuser || 0;
      this.profile = data.profile
        ? {
            controllers: data.profile.controllers,
            icon: data.profile.icon,
            name: data.profile.name,
          }
        : { controllers: [] };
    },

    addMenuLastPage(data) {
      let menuLastPage = {
        ...this.preference.menuLastPage,
      };

      Object.keys(data).forEach(key => {
        menuLastPage[key] = data[key];
      });

      this.preference.menuLastPage = menuLastPage;
    },
    updatePrimaryTheme(color) {
      this.preference.theme.primary = color;
    },
    updateTheme(dark) {
      this.preference.theme.dark = dark;
    },
    updateLanguage(language) {
      this.preference.language = language;
    },
    setLanguage(language) {
      if (!this.preference) this.preference = {};
      this.preference.language = language;
    },
    updateSounds(type, sound) {
      this.preference.sounds[type] = sound;
    },

    updateAvatar(avatar) {
      this.user.avatar = avatar != "" ? `${JSON.stringify(avatar)}` : "";
    },
    updateSubmenu() {
      this.preference.submenuOpen = !this.submenuOpen;
    },
    setLastCreatedInteraction(channel, campaign, country) {
      if (
        !this.preference.lastCreatedInteraction ||
        !this.preference.lastCreatedInteraction.country
      )
        this.preference.lastCreatedInteraction = {
          campaign: "",
          channel: "",
          country: {},
        };

      this.preference.lastCreatedInteraction.campaign = campaign;
      this.preference.lastCreatedInteraction.channel = channel;

      this.preference.lastCreatedInteraction.country[channel] = country;
    },
    addFullscreenForm(form) {
      if (form) this.preference.fullscreenForms.push(form);
    },
    removeFullscreenForm(form) {
      if (form)
        this.preference.fullscreenForms = this.preferences[
          this.user.username
        ].fullscreenForms.filter(f => form != f);
    },

    updateRankingTemplates(name) {
      let update = false;
      this.preference.rankingTemplates.forEach(item => {
        if (item.name == name) {
          item.rank = item.rank + 1;
          update = true;
        }
      });
      if (!update)
        this.preference.rankingTemplates.push({
          name: name,
          rank: 1,
        });
    },
    updateRankingStatus(name) {
      let update = false;
      this.preference.rankingStatus.forEach(item => {
        if (item.name == name) {
          item.rank = item.rank + 1;
          update = true;
        }
      });
      if (!update)
        this.preference.rankingStatus.push({
          name: name,
          rank: 1,
        });
    },
    updateTabs(type, tabs) {
      this.preference.tabs[type] = tabs;
    },
    updateDashboard(type, dashboard) {
      this.preference.dashboards[type] = dashboard;
    },
    updateStartAsUnavailable() {
      this.preferences[this.user.username].startAsUnavailable =
        this.preference.startAsUnavailable;

      this.updatePreferences();
    },

    clear() {
      if (!this.user) return;
      this.preferences[this.user.username] = this.preference;

      this.user = null;
      this.profile = null;
      this.preference = null;
      this.preDispositions = null;
    },
    setPreDispositions(guid, disposition) {
      if (!this.preDispositions) this.preDispositions = {};

      this.preDispositions = { ...this.preDispositions, [guid]: disposition };
    },
    clearPreDispositions(guid) {
      delete this.preDispositions[guid];
      this.preDispositions = { ...this.preDispositions };
    },
    setPhone(phone) {
      this.user.phone = phone;
    },
    updateScheduled(scheduled) {
      this.user.scheduled = scheduled;
    },
    addEmojiToRecent(emoji) {
      if (this.preference.emojisRecent.length >= 40)
        this.preference.emojisRecent.shift();

      this.preference.emojisRecent = this.preference.emojisRecent.filter(
        e => e != emoji
      );

      this.preference.emojisRecent.unshift(emoji);
    },

    setEmojiSkinTone(tone) {
      this.preference.emojisSkinTone = tone;
    },

    setCRM(crmDomain) {
      this.user.crmDomain = crmDomain;
    },

    updateServerDate(date) {
      this.serverDate = date;
    },

    setInteractionDrawer(value) {
      this.preference.interactionDrawer = value;
    },

    toggleInteractionDrawer() {
      this.preference.interactionDrawer = !this.preference.interactionDrawer;
    },

    setServerDiff(diff) {
      this.serverDiff = diff;
    },
    getServerDiff() {
      return this.serverDiff;
    },
  },

  persist: true,

  undo: {
    disable: true,
  },
});
