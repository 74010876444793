import {
  useAppStore,
  usePhoneStore,
  useInboxStore,
  useAxios,
  dateToUtc,
  logout,
  appUrl,
  useWebRTC,
  isUUID,
} from "@/utils";

import ReconnectingWebSocket from "reconnecting-websocket";

import Vue from "vue";
import { i18n } from "@/plugins/i18n";
import WindowProxy from "@/controllers/windowProxy";

class Historic {
  constructor(
    id,
    guid,
    clientId,
    clientName,
    finishDate,
    channel,
    campaign,
    direction,
    others,
    dispositions
  ) {
    this.id = id;
    this.guid = guid;
    this.clientId = clientId;
    this.clientName = clientName;
    this.finishDate = finishDate;
    this.channel = channel;
    this.campaign = campaign;
    this.direction = direction;
    this.others = others;
    this.dispositions = dispositions;
    this.messages = [];
  }
}
export class Inbox {
  constructor(data = {}) {
    this.data = data;
  }

  connect(username) {
    useInboxStore().clear();
    const userUrl = username
      ? username + "/" + useAppStore().user.token
      : useAppStore().user.username;
    let websocketUrl;
    let host = location.host;
    host.includes("localhost")
      ? (websocketUrl = `wss://${appUrl}/inbox/${userUrl}`)
      : (websocketUrl = `wss://${host}/inbox/${userUrl}`);
    this.server = new ReconnectingWebSocket(websocketUrl);
    this.server.onerror = event => {
      console.error("WebSocket error:", event);
    };
    this.server.onopen = () => {
      useInboxStore().clear();
      useInboxStore().setStatus("Connecting");
      this.interval = setInterval(() => {
        this.server.send("{}");
      }, 50000);
    };
    this.server.onclose = event => {
      if (useInboxStore().status == "Already connected") {
        this.server.close();
        clearInterval(this.interval);

        return;
      }
      if (event) useInboxStore().setError();
      useInboxStore().setStatus("Reconnecting");
      useInboxStore().clearNotifications();
    };
    this.server.onmessage = event => {
      let data = JSON.parse(event.data);
      let get;
      switch (data.type) {
        case "connectionSuccess":
          useInboxStore().setStatus("Connected");
          useWebRTC().initConfigs();
          break;
        case "alreadyConnected":
          useInboxStore().setStatus("Already connected");
          usePhoneStore().getPhone().unregister();
          break;
        case "assignAgent":
          useInboxStore().addInteraction(data.data, username);
          WindowProxy.assignedInteraction(data.data);
          window.dispatchEvent(
            new CustomEvent("assignedInteraction", { detail: data.data })
          );
          break;
        case "removeInteraction":
          window.dispatchEvent(
            new CustomEvent("removedInteraction", { detail: data.guid })
          );
          setTimeout(() =>
            useInboxStore().removeInteraction(
              data.guid,
              data.data?.interactionId
            )
          );
          break;
        case "dismissAgent":
          useInboxStore().removeInteraction(data.guid);
          break;
        case "finishInteraction":
          useInboxStore().finishInteraction(data.guid, data.data.finishDate);
          WindowProxy.finishInteraction(data.guid, data.data);
          break;
        case "addMessage":
          useInboxStore().addMessage(data.guid, data.data);
          break;
        case "updatemsgStatus":
          useInboxStore().updateMessage(data.guid, data.data);
          break;
        case "updateContactId":
          useInboxStore().updateContactId(data.guid, data.data);
          break;
        case "sentDispositions":
          useInboxStore().sentDispositions(data.guid, data.data.interactionId);
          break;
        case "sentDialerDispositions":
          useInboxStore().sentDispositions(data.guid, 0);
          break;
        case "status":
          useInboxStore().setPauseStatus(
            data.data.status,
            data.data.startBreak
          );
          break;
        case "newAgentConnection":
        case "newAgentConnectionSpy":
          useInboxStore().handleNewAgentConnection(data.data);

          get = async () => {
            const statuses = await this.getStatuses();
            statuses.forEach(s => {
              useInboxStore().pause.breaks.push(s);
            });
          };
          get();
          break;
        case "newRingingInteraction":
          useInboxStore().addRingingInteraction(data.data);
          break;
        case "stopRingingInteraction":
          useInboxStore().removeRingingInteraction(data.guid);
          break;
        case "contactConnection":
          useInboxStore().updateContactState(data.data.contact, true);
          break;
        case "contactDisconnection":
          useInboxStore().updateContactState(data.data.contact, false);
          break;
        case "updateUnread":
        case "updateInternalUnread":
          useInboxStore().updateUnread(data.guid, data.data.unread);
          break;
        case "reloadContacts":
          useInboxStore().reloadContacts(data.data);
          break;
        case "addContact":
          useInboxStore().addContact(data.data);
          break;
        case "updateContactAvatar":
          useInboxStore().updateContactAvatar(data.data);
          break;
        case "removeContact":
          useInboxStore().removeContact(data.data.contact);
          break;
        case "addCampaign":
          useInboxStore().addCampaign(data.data);
          break;
        case "removeCampaign":
          useInboxStore().removeCampaign(data.data.campaign);
          break;
        case "newParkCall":
          useInboxStore().addParkCall(data.data);
          break;
        case "unPark":
          useInboxStore().removeParkCall(data.guid);
          if (data.data.type == "unPark") {
            let interaction = useInboxStore().interactions.find(
              i => i.guid == data.guid
            );
            usePhoneStore().setClient({
              userNumber: interaction.clientId,
              username: interaction.clientName,
              campaign: interaction.campaign,
              guid: interaction.guid,
            });

            // router.push({
            //   path: "/inbox",
            //   query: { name: "phone" },
            // });
          }
          break;
        case "addNotification":
          useInboxStore().addNotification(this.manageNotification(data.data));
          break;
        case "updateProfile":
          useAppStore().updateProfile(data.data);
          break;
        case "emailMessageError":
          useInboxStore().emailMessageError(data.guid, data.data);
          break;
        case "blacklistInteraction":
          Vue.notify({
            group: "app",
            duration: 4000,
            text: i18n.t(data.data.message, data.data),
          });
          break;
        case "alreadyConnectedInteraction":
          Vue.notify({
            group: "app",
            duration: 4000,
            text: i18n.t(
              "An interaction with the client clientId is already active",
              data.data
            ),
          });
          break;
        case "alreadyInQueueInteraction":
          Vue.notify({
            group: "app",
            duration: 4000,
            text: i18n.t(
              "An interaction with the client clientId is already in queue",
              data.data
            ),
          });
          break;
        case "wrongFormatInteraction":
          Vue.notify({
            group: "app",
            duration: 4000,
            text: i18n.t(
              "Interaction with clientId couldn't start because it had the wrong format",
              data.data
            ),
          });
          break;
        case "campaignDoesNotExist":
          Vue.notify({
            group: "app",
            duration: 4000,
            text: i18n.t("Campaign does not exist"),
          });
          break;
        case "providerDoesNotExist":
          Vue.notify({
            group: "app",
            duration: 4000,
            text: i18n.t("Provider does not exist"),
          });
          break;
        case "sendMessengerError":
          Vue.notify({
            group: "app",
            duration: 4000,
            text: i18n.t("There was an error sending the messenger message"),
          });
          break;
        case "servedForm":
          useInboxStore().setServedForm(data.data.form);
          break;
        case "codeServer":
          useInboxStore().setCodeServer(data.data.codeServer);
          break;
        case "updateLoadingForms":
          useInboxStore().setLoadingForms(data.data);
          break;
        case "logout":
          logout();
          break;
        case "updateInteractionData":
          useInboxStore().updateInteractionData(data.guid, data.data);
          break;
        case "startSpied":
          if (data.data.supervisor == useAppStore().user.username) return;
          useWebRTC().startSpied(data.data.supervisor, data.data.data);
          break;
        case "stopSpied":
          if (data.data.supervisor == useAppStore().user.username) return;
          useWebRTC().stopSpied(data.data.supervisor);
          break;
        case "spiedSignal":
          useWebRTC().spiedSignal(data.data.data);
          break;
        case "startWrapup":
          useInboxStore().setWrapup(true, data.data.end);
          break;
        case "stopWrapup":
          useInboxStore().setWrapup(false);
          break;
        case "unite":
          useInboxStore().setUnite(data.data.unite);
          break;
        case "updatePhone":
          useAppStore().setPhone(data.data.phone);
          this.connectPhone();
          break;
        case "setDispositions":
          useInboxStore().setDispositions(data.data.dispositions);
          break;
        case "addPhonebook":
          useInboxStore().addPhonebook(data.data);
          break;
        case "updatePhonebook":
          useInboxStore().updatePhonebook(data.data);
          break;
        case "deletePhonebook":
          useInboxStore().deletePhonebook(data.data);
          break;
        case "updateScheduled":
          useAppStore().updateScheduled(data.data.scheduled);
          break;
        case "transferredInteractions":
          useInboxStore().setTransferredInteractions(data.data.interactions);
          break;
        case "addConference":
          data.data.micsOn = JSON.parse(data.data.micsOn);
          useInboxStore().setConference(data.data);
          break;
        case "endConference":
          useInboxStore().endConference(data.data);
          break;
        case "addConferenceMember":
          data.data.micsOn = JSON.parse(data.data.micsOn);
          useInboxStore().addConferenceMember(data.data);
          break;
        case "removeConferenceMember":
          useInboxStore().removeConferenceMember(
            data.data.member,
            data.data.conference
          );
          break;
        case "kickMember":
          useInboxStore().endConference(data.data);
          break;
        case "muteMember":
          if (data.data.mute) {
            usePhoneStore().getPhone().mute();
          } else {
            usePhoneStore().getPhone().unMute();
          }
          break;
        case "muteConferenceMember":
          useInboxStore().muteConferenceMember(data.data);
          break;
        case "setTalking":
          useInboxStore().setTalking(data.data);
          break;
        case "updateInteractionClientName":
          useInboxStore().updateInteractionClientName(data.data);
          break;
        case "addTemplate":
          useInboxStore().addTemplate(data.data);
          break;
        case "removeTemplate":
          useInboxStore().removeTemplate(data.data);
          break;
        case "consultationCall":
          useInboxStore().consultationCall(
            data.guid,
            data.data.consultationCall
          );
          break;
        case "changeInteractionCampaign":
          useInboxStore().changeInteractionCampaign(
            data.guid,
            data.data.campaign
          );
          break;
        default:
          console.log("Unknown message type", data);
          break;
      }
    };
    this.server.onError = event => {
      console.error("error", event);
    };
    useInboxStore().setWebSocket(this.server);
  }
  sendWsMessage(message) {
    if (this.server) {
      this.server.send(JSON.stringify(message));
    }
  }

  close(reason) {
    if (this.server) {
      if (reason == "alreadyConnected")
        useInboxStore().setStatus("Already connected");
      this.server.close();
    }
  }
  async connectPhone() {
    const selfView = document.getElementById("selfView");
    const remoteView = document.getElementById("remoteView");
    usePhoneStore().getPhone().unregister();
    await usePhoneStore()
      .getPhone()
      .innit(
        useAppStore().user.host,
        useAppStore().user.phone,
        useAppStore().user.md5Secret,
        remoteView,
        selfView
      );
    usePhoneStore().getPhone().connectPhone(true);
  }

  async startInteraction(
    client,
    campaign,
    channel,
    others,
    message,
    attachments,
    contactId
  ) {
    const res = await useAxios({
      method: "POST",
      url: "/api/inbox/startInteraction",
      data: {
        client: client,
        campaign: campaign,
        channel: channel,
        others: others,
        message: message,
        attachments: attachments,
        contactId: contactId,
      },
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }
  }

  async finishInteraction(guid) {
    const res = await useAxios({
      method: "PUT",
      url: `/api/inbox/id/${guid}/finish`,
      data: {},
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }
  }

  async getUserCampaigns() {
    let res = await useAxios({
      method: "get",
      url: `/api/inbox/campaigns`,
      headers: {},
    });

    if (res.success) {
      res = res.res.data.result;

      return res;
    } else {
      console.log(res.res);

      return [];
    }
  }

  async removeInteraction(guid, interactionId) {
    let id = guid;
    if (interactionId && isUUID(guid)) id += `?interactionId=${interactionId}`;

    const res = await useAxios({
      method: "DELETE",
      url: `/api/inbox/id/${id}`,
      data: {},
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }
  }

  async transfer(guid, type, destinationType, destination) {
    const res = await useAxios({
      method: "PUT",
      url: `/api/inbox/id/${guid}/transfer`,
      data: {
        type: type,
        destinationType: destinationType,
        destination: destination,
      },
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  async answer(guid) {
    const res = await useAxios({
      method: "PUT",
      url: `/api/inbox/id/${guid}/answer`,
      data: {},
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }
  }

  async sendMessage(guid, text, attachments, others) {
    const res = await useAxios({
      method: "POST",
      url: `/api/inbox/id/${guid}/sendMessage`,
      data: {
        text: text,
        attachments: attachments,
        others: others,
      },
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }
  }

  async sendDisposition(
    guid,
    id,
    levels,
    comment,
    schedule,
    action,
    rescheduleDate,
    assignToMe,
    interactionId
  ) {
    const res = await useAxios({
      method: "POST",
      url: `/api/inbox/id/${guid}/dispositions`,
      data: {
        id: id,
        levels: levels,
        comment: comment,
        action: action,
        schedule: schedule,
        rescheduleDate: rescheduleDate || null,
        assignToMe,
        interactionId: interactionId,
      },
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  async loadMessages(guid, firstMessageId) {
    let res = await useAxios({
      method: "get",
      url: `/api/inbox/id/${guid}/messages`,
      params: {
        firstMessageId: isNaN(firstMessageId) ? "" : firstMessageId,
      },
      headers: {},
    });

    if (res.success) {
      res = res.res;
      let messages = res.data.result.messages;
      if (!messages || messages.length == 0) return;
      if (!firstMessageId || firstMessageId == -1)
        useInboxStore().clearMessages(guid);
      messages.forEach(m => useInboxStore().loadMessage(guid, m));
    } else {
      console.log(res.res);
    }
  }

  async readMessages(guid) {
    const res = await useAxios({
      method: "PUT",
      url: `/api/inbox/id/${guid}/read`,
      data: {},
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }
  }

  async addAttachment(file, guid, addFilename = false) {
    let url = guid
      ? "/api/inbox/attachment?filename=" + file.name + "&guid=" + guid
      : "/api/inbox/attachment?filename=" + file.name;

    url += addFilename ? `&addFilename=true` : ``;

    const res = await useAxios({
      method: "POST",
      url: url,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
    if (!res.success) {
      console.log(res.res);
    } else {
      let obj = {};
      obj.name = file.name;
      obj.type = file.type.includes("application")
        ? file.type
        : file.type.split("/")[0];
      obj.url = res.res.data.result.url.replaceAll(`"`, "");

      return obj;
    }
  }

  async removeAttachment(attachment) {
    const res = await useAxios({
      method: "DELETE",
      url: `/api/inbox/attachment`,
      data: {
        filename: attachment.url,
      },
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    } else {
      return res.res.data.result.status;
    }
  }

  async loadHistoric(reload) {
    let lastInteractionId =
      !reload && useInboxStore().historic.length
        ? useInboxStore().historic[useInboxStore().historic.length - 1].id
        : 0;
    let res = await useAxios({
      method: "get",
      url: `/api/inbox/historic`,
      params: {
        lastInteractionId: lastInteractionId,
      },
      headers: {},
    });

    if (res.success) {
      let interactions = res.res.data.result.interactions;
      if (reload) useInboxStore().clearHistoric();
      interactions.forEach(interaction => {
        if (interaction.channel !== "facebook") {
          let others = null;
          if (interaction.channel == "email") {
            others = { subject: interaction.subject };
          }
          if (interaction.channel == "telephony") {
            others = { duration: interaction.duration };
          }
          useInboxStore().addHistoric(
            new Historic(
              interaction.id,
              interaction.guid,
              interaction.clientid,
              interaction.clientname,
              interaction.end_date,
              interaction.channel,
              interaction.campaign,
              interaction.direction,
              others,
              {
                levels: interaction.levels
                  ? JSON.parse(interaction.levels)
                  : [],
                comment: interaction.comment,
              }
            )
          );
        }
      });
    } else {
      console.log(res.res);
    }
  }

  async loadHistoricMessages(guid, channel, firstMessageId) {
    let res = await useAxios({
      method: "get",
      url: `/api/inbox/id/${guid}/historic?channel=${channel}&firstMessageId=${firstMessageId}`,
      headers: {},
    });

    if (res.success) {
      let messages = res.res.data.result.messages;
      useInboxStore().loadHistoricMessages(guid, messages);
    }
  }

  async setFavoriteContact(contact) {
    const res = await useAxios({
      method: "PUT",
      url: `/api/inbox/id/${useAppStore().user.username}/favorites`,
      data: {
        contact: contact.username,
        fav: !useInboxStore().favorites.find(fav => fav == contact.username)
          ? 1
          : 0,
      },
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    } else {
      useInboxStore().setFavorite(contact.username);
    }
  }

  async removeNotification(notification) {
    let n = JSON.parse(JSON.stringify(notification));
    n.date = dateToUtc(n.date).toFormat("yyyy-MM-dd HH:mm:ss");
    const res = await useAxios({
      method: "DELETE",
      url: `/api/inbox/notification`,
      data: n,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    } else {
      useInboxStore().removeNotification(notification);
    }

    return res.res;
  }

  async removeAllNotification() {
    const res = await useAxios({
      method: "DELETE",
      url: `/api/inbox/notifications`,
      data: {},
      headers: {
        Authorization: "Bearer " + useAppStore().user.token,
      },
    });
    if (!res.success) {
      console.log(res.res);
    } else {
      useInboxStore().removeAllNotification();
    }

    return res.res;
  }
  playSound(source) {
    if (source && !useInboxStore().spying) {
      let sound = require(`../assets/sounds/${source}.ogg`);
      let audio = new Audio(sound);
      audio.volume = useAppStore().preference.sounds.volume / 100;
      audio.play();
    }
  }

  async getStatuses(date) {
    let res = await useAxios({
      method: "get",
      url: `/api/inbox/statuses`,
      params: {
        date: date,
      },
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    }
  }

  async disconnectInbox() {
    const res = await useAxios({
      method: "DELETE",
      url: `/api/inbox/inbox`,
      headers: {},
    });
    if (!res.success) {
      console.log(res.res);
    }

    return res;
  }

  manageNotification(n) {
    // n = { date: "2021-05-25 10:00:00", text: "test", params:{}}
    n.description = n.params.description;
    n.options = n.params.options;

    this.notificationMethods(n);

    // n.textShow = i18n.t(n.text, n.params);

    return n;
  }
  notificationMethods(n) {
    switch (n.text) {
      case "Form project for form successfully built":
        useInboxStore().addLoadingFormStatus(n.params.form, "built");
        break;
      case "An error occurred while building form's form project":
        useInboxStore().addLoadingFormStatus(n.params.form, "errorBuild");
        break;
      case "An error occurred while serving form's form project":
        useInboxStore().setServeError(true);
        break;
      case "Form project for form successfully deleted":
        useInboxStore().addLoadingFormStatus(n.params.form, "deleted");
        break;
      case "An error occurred while deleting form's form project":
        useInboxStore().addLoadingFormStatus(n.params.form, "errorDeleting");
        break;
      case "There were errors creating the following users:":
        n.params.users = n.params.users
          .map(user => `${user.user}: ${i18n.t(user.message)}`)
          .join(" ");
        break;
    }
  }

  async getContactsLogged() {
    let res = await useAxios({
      method: "get",
      url: `/api/inbox/contactsLogged`,
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  async getLicensesInfo() {
    let res = await useAxios({
      method: "get",
      url: `/api/inbox/licensesInfo`,
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  async startSpy(agent) {
    let res = await useAxios({
      method: "post",
      url: `/api/inbox/spy?agent=${agent}`,
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }
  async stopSpy(agent) {
    let res = await useAxios({
      method: "delete",
      url: `/api/inbox/spy?agent=${agent}`,
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }
  async stopWrapup() {
    let res = await useAxios({
      method: "delete",
      url: `/api/inbox/wrapup`,
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  async generateThumbnailVideo(fullPath) {
    let res = await useAxios({
      method: "POST",
      url: `/api/inbox/thumbnail`,
      data: {
        url: fullPath,
      },
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  async setContactId(guid, contactId) {
    let res = await useAxios({
      method: "PUT",
      url: `/api/inbox/id/${guid}/contactId`,
      data: {
        contactId: contactId,
      },
    });

    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  // Conference
  async startConference(client, destination, guid) {
    let res = await useAxios({
      method: "POST",
      url: `/api/inbox/conference/start`,
      data: {
        client: client,
        destination: destination,
        guid: guid,
      },
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  async setContactIdByClientId(campaign, clientId, contactId) {
    let res = await useAxios({
      method: "PUT",
      url: `/api/inbox/contactId`,
      data: {
        campaign: campaign,
        clientId: clientId,
        contactId: contactId,
      },
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }

  async kickMember(number, guid) {
    let res = await useAxios({
      method: "POST",
      url: `/api/inbox/conference/removeMember`,
      data: {
        member: number,
        guid: guid,
      },
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }

    console.log(number);
  }

  async muteMember(admin, number, mute, guid) {
    let res = await useAxios({
      method: "POST",
      url: `/api/inbox/conference/mute`,
      data: {
        admin: admin,
        member: number,
        mute: mute,
        guid: guid,
      },
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }

    console.log(number);
  }

  async muteAll() {
    let res = await useAxios({
      method: "POST",
      url: `/api/inbox/conference/muteAll`,
      data: {},
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }
  async muteParticipants() {
    let res = await useAxios({
      method: "POST",
      url: `/api/inbox/conference/muteParticipants`,
      data: {},
      headers: {},
    });
    if (res.success) {
      return res.res.data.result;
    } else {
      console.log(res);
    }
  }
}
